@use '../abstracts/variables' as *;

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 0;
  outline: 0;
  font-family: $ff-primary;
  font-weight: 500;
  font-size: 0.875rem;
  height: 32px;
  padding: 0 13px;
  border-radius: 3px;
  cursor: pointer;
  background-color: $tertiary-bg-color;
  color: $quinary-text-color;
  transition: background-color .3s ease;

  &:hover {
    background-color: $quinary-bg-color;
  }

  &:active {
    background-color: darken($quinary-bg-color, 10%);
  }

  .icon {
    display: block;
    margin-right: 11px;

    svg {
      display: block;
    }
  }

  .arrow {

    svg {
      display: block;
    }
  }

  &--drop-down {
    padding-right: 7px;
  }

  &--bold {
    font-weight: 700;
  }

  &--regular {
    font-weight: 400;
  }

  &--small {
    height: 24px;
    padding: 0 10px;
    font-size: 0.75rem;
  }

  &--x-small {
    height: 20px;
    padding: 0 8px;
    font-size: 0.6875rem;
  }

  &--medium {
    height: 40px;
    padding: 0 20px;
  }

  &--with-padding-y {
    padding-top: 12px;
    padding-bottom: 12px;
    height: auto;
  }

  &--primary {
    background-color: $primary-color;
    color: $primary-light-color;

    &:hover {
      background-color: darken($primary-color, 10%);
    }

    &:disabled {
      cursor: not-allowed;
    }

    &:active {
      background-color: darken($primary-color, 20%);
    }
  }

  &--secondary-light {
    background-color: $quinary-color-light;
    color: $septenary-color;

    &:hover {
      background-color: darken($quinary-color-light, 10%);
    }
    &:disabled {
      background-color: $quinary-color-light;
    }
    &:active {
      background-color: darken($quinary-color-light, 20%);
    }
  }

  &--outline-primary {
    background-color: transparent;
    border: 1px solid $primary-color;
    color: $primary-color;
    font-weight: 500;
    transition: color .3s ease, background-color .3s ease;

    &:hover {
      background-color: $primary-color;
      color: $primary-light-color;
    }
    &:active {
      background-color: darken($primary-color, 10%);
    }
  }

  &--outline-primary-light {
    background-color: $primary-light-color;
    border: 1px solid rgba($primary-color, 0.43);
    color: $primary-color;
    font-weight: 500;
    transition: color .3s ease, background-color .3s ease;

    &:hover {
      background-color: rgba($primary-color, 0.43);
      color: $primary-light-color;
      border-color: rgba($primary-color, 0.43);
    }
    &:active {
      color: $primary-light-color;
      background-color: $primary-color;
    }
  }

  &--outline-pink {
    background-color: transparent;
    border: 1px solid $bg-color-16;
    color: $bg-color-16;
    font-weight: 400;
    transition: color .3s ease, background-color .3s ease;

    &:hover {
      background-color: $bg-color-16;
      color: $primary-light-color;
      font-weight: 500;
    }
    &:active {
      background-color: darken($bg-color-16, 10%);
    }
  } 

  &--white {
    background-color: $primary-light-color;
    color: $primary-color;
    box-shadow: 0 2px 4px 0 rgba($shadow-color-4, 0.5);

    &:active {
      background-color: darken($primary-light-color, 10%);
    }
  }

  &--primary-light {
    background-color: rgba($primary-color, 0.1);
    color: $primary-color;
    transition: background-color .3s ease;

    &:hover {
      background-color: rgba($primary-color, 0.3);
    }
    &:active {
      background-color: rgba($primary-color, 0.5);
    }
  }

  &--danger {
    background-color: $danger-color;
    color: $primary-light-color;
    transition: background-color .3s ease;
    
    &:hover {
      background-color: darken($danger-color, 10%);
      color: $primary-light-color;
    }
    &:active {
      background-color: darken($danger-color, 20%);
    }
  }

  &--transparent {
    background-color: transparent;
    color: $secondary-text-color;
    padding: 0;

    &:hover {
      background-color: transparent;
    }
    &:active {
      background-color: transparent;
    }
  }

  &--full-width {
    width: 100%;
  }

  &--smaller-icon-margin {

    .icon {
      margin-right: 5px;
    }
  }

  &--txt-small {
    font-size: 0.6875rem;
    text-transform: uppercase;
    font-weight: 700;
    padding: 0 8px;
    
    span {
      display: block;
    }

    .icon {
      margin-right: 6px;
    }
  }

  &--text-uppercase {
    text-transform: uppercase;
    letter-spacing: 0.3px;
  }

  &--font-small {
    font-size: 0.75rem;
  }
}