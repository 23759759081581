.help-search-results {

  &__head {
    margin-bottom: 20px;

    h2 {
      font-size: 1.875rem;
      font-weight: 400;
      margin-bottom: 15px;

      span {
        font-weight: 700;
      }
    }

    p {
      color: #aaa;
      font-size: 0.875rem;
    }
  }

  &__body {

    ul {
      padding: 0;
      list-style: none;

      li {

        a {
          display: inline-flex;
          align-items: center;
          color: #5D6C85;
          text-decoration: none;
          line-height: 1.5;
  
          svg {
            display: block;
            margin-right: 5px;
          }

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}