@use '../abstracts/variables' as *;

.custom-popover {
  position: absolute;
  top: calc(100% + 5px);
  left: 50%;
  display: flex;
  align-items: center;
  white-space: nowrap;
  background-color: $primary-light-color;
  box-shadow: 0 3px 8px 0 rgba($shadow-color-5, 0.5);
  padding: 5px;
  opacity: 0;
  transform: scale(0) translateX(-50%);
  transition: opacity .3s ease, transform .3s ease;
  transform-origin: left top;
  backface-visibility: hidden;

  &.active {
    opacity: 1;
    transform: scale(1) translateX(-50%);
  }
}