@font-face {
  font-family: "Akira Expanded";
  src: url("../assets/fonts/AkiraExpanded-Bold.woff2") format("woff2"),
    url("../assets/fonts/AkiraExpanded-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Akira Expanded";
  src: url("../assets/fonts/AkiraExpanded.woff2") format("woff2"),
    url("../assets/fonts/AkiraExpanded.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}