@use '../abstracts/variables' as *;

.support-page {
  padding: 12px 0 100px;
  flex: 1;

  &__container {
    max-width: 1080px;
    margin: 0 auto;
    padding: 0 15px;
  }

  &__head {
    text-align: center;
    padding: 50px 0;
    border-bottom: 1px solid #EBECF0;
    margin-bottom: 50px;

    h1 {
      color: #5D6C85;
    }
  }

  &__body {

    &--flex {
      display: flex;
      flex-wrap: wrap;

      > aside {
        width: 200px;
        margin-right: 40px;

        @media (max-width: $break-md-max) {
          width: 100%;
          margin-bottom: 40px;
          margin-right: 0;
        }

        h4 {
          text-transform: uppercase;
          font-size: 0.875rem;
          font-weight: 700;
          letter-spacing: 1px;
          margin-bottom: 20px;
          cursor: pointer;
        }

        ul {
          list-style: none;
          padding-left: 0;

          li {
            font-size: 0.875rem;
            margin-bottom: 5px;

            a {
              color: #5D6C85;
              display: flex;
              align-items: center;
              line-height: 24px;
              text-decoration: none;
              letter-spacing: 0;

              svg {
                display: none;
              }

              &:hover {
                color: darken(#5D6C85, 10);
              }
            }

            &.active {

              a {
                font-weight: 700;

                &:hover {
                  color: #5D6C85;
                }

                svg {
                  display: block;
                }
              }
            }
          }
        }

        .back {
          color: #5D6C85;
          display: flex;
          align-items: center;
          margin-top: 30px;
          font-size: 0.875rem;
          border-top: 1px solid #EBECF0;
          padding-top: 10px;
          text-decoration: none;
          letter-spacing: 0;

          &:hover {
            color: #000;
          }
        }
      }

      > main {
        flex: 1;
      }
    }
  }

  &__no-data {
    border: 1px solid #EBECF0;
    background-color: #F5F6F8;
    padding: 50px 20px;

    p {
      font-size: 1rem;
      text-align: center;
    }
  }
}