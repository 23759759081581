@use '../abstracts/variables' as *;

.document-detail {
  min-height: 100%;
  background-color: #F9F9FB;
  display: flex;
  flex-direction: column;
  padding-top: 39px;

  .row {
    display: flex;
    flex-direction: row;
  }

  .row.v-centered {
    align-items: center;
  }

  .ml-5 {
    margin-left: 5px;
  }

  @media (max-width: $break-md-max) {
    padding-top: 39px;
  }
  
  &__head {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 11;
    box-shadow: 0 2px 4px 0 rgba($shadow-color-2, 0.5);
    padding: 12px 64px;
    margin-bottom: 26px;
    background-color: $primary-light-color;

    @media (max-width: $break-lg-max) {
      padding: 12px 15px;
    }
    
    .container {
      display: flex;
      align-items: center;

      @media (max-width: $break-md-max) {
        flex-wrap: wrap;
      }
    }

    &_left {
      display: flex;
      align-items: center;
      margin-right: auto;
    }

    &_back {
      font-size: 1.5rem;
      color: rgba($primary-text-color, 0.2);
      cursor: pointer;
      display: flex;
      align-items: center;
      margin-right: 31px;
      transition: color .3s ease;
      width: 24px;
      min-width: 24px;
      height: 100%;
      position: relative;
      transition: width .3s ease, color .3s ease;
      overflow: hidden;

      @media (max-width: $break-xs-max) {
        margin-right: 10px;
      }

      svg {
        display: block;
        text-align: center;
      }

      &:hover {
        color: $primary-color;
        width: 100px;

        span {
          transform: translateY(-50%);
          opacity: 1;
        }

        @media (max-width: $break-xs-max) {
          width: 24px;

          span {
            display: none;
          }
        }
      }

      span {
        font-size: 0.875rem;
        color: $primary-text-color;
        position: absolute;
        top: 50%;
        left: 24px;
        transform: translateY(calc(-50% - 20px));
        opacity: 0;
        transition: transform .3s ease, opacity .3s ease;
      }
    }

    &_main {

      .title {
        font-size: 1rem;
        font-weight: 700;
        margin-bottom: 6px;
        position: relative;
        display: inline-flex;

        .transparent {
          opacity: 0;
          padding: 0 5px;
          display: block;
          height: 19px;
        }

        .input-wrapper {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;

          input {
            width: 100%;
            min-width: 1px;
          }
        }
      }

      .status-wrap {
        display: flex;
        align-items: center;

        &__left {
          margin-right: 25px;
          display: flex;

          @media (max-width: $break-xs-max) {
            margin-right: 15px;
          }
        }

        &__right {
          font-size: 0.8125rem;
          color: $quinary-text-color;
        }
      }
    }

    &_actions {
      display: flex;
      align-items: center;
      position: relative;
      margin-left: 15px;

      @media (max-width: $break-md-max) {
        width: 100%;
        margin-top: 20px;
        margin-left: 0;

        .icon-button {
          margin-left: auto;
        }
      }

      @media (max-width: 420px) {
        .icon-button {
          width: 36px;
          min-width: 36px;

          svg {
            font-size: 1.25rem;
          }
        }
      }

      .tooltip-wrapper {
        height: 30px;
      }

      .btn-wrapper {
        margin-right: 4px;
        position: relative;
      }

      .button {
        margin-right: 4px;
        height: 30px;

        &.save-btn {
          min-width: 124px;

          @media (max-width: $break-xs-max) {
            min-width: 50px;
            .text {
              display: none;
            }
            .icon {
              margin-right: 0;
            }
          }
          @media (max-width: 420px) {
            min-width: 36px;

            svg {
              font-size: 1.25rem;
            }
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }

      .dropdown-actions {
        position: absolute;
        top: calc(100% + 9px);
        right: 0;
        width: 152px;
        border-radius: 5px;
        background-color: $primary-light-color;
        box-shadow: 0 3px 8px 0 rgba($shadow-color-5, 0.5);
        padding: 15px 0;
        // opacity: 0;
        // transition: opacity .3s ease, transform .3s ease;
        // transform: scale(0.6);
        // backface-visibility: hidden;

        // &.active {
        //   opacity: 1;
        //   transform: scale(1);
        // }

        ul {

          li {
            display: flex;
            align-items: center;
            height: 30px;
            padding-left: 20px;
            font-size: 0.875rem;
            cursor: pointer;
            transition: background-color .3s ease;
            margin-bottom: 5px;

            &:last-child {
              margin-bottom: 0;
            }

            svg,
            i {
              color: $octonary-text-color;
              display: block;
              margin-right: 8px;
              font-size: 1.5rem;
            }

            &:hover {
              background-color: $tertiary-bg-color;
            }
          }
        }
      }
    }

    .scrolling-indicator {
      position: absolute;
      top: 100%;
      left: 0;
      height: 2px;
      background-color: $primary-color;
    }
  }

  &__body {
    flex-grow: 1;
    position: relative;
    padding: 0 64px;
    transition: width .3s ease;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    @media (max-width: $break-lg-max) {
      padding: 0 0 0 15px;
    }
    
    &_inner {
      margin: 0 auto;
      max-width: 1151px;
      width: 100%;
      position: relative;
      padding-right: 64px;
      flex-grow: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
      /* SCROLLING TASK */
      // padding-bottom: 500px;
      /* SCROLLING TASK */ 
      
      .wrap {
        background-color: $primary-light-color;
        box-shadow: 3px 6px 8px 0 #E7E7E7;
        flex-grow: 1;
        padding-top: 30px;
        // padding-bottom: 80vh;

        input[type="text"]:focus, 
        input[type="string"]:focus,
        input[type="number"]:focus,
        textarea:focus {
          border: 1px solid $primary-color;
        }
      }
    }

    .side-panel--2 {
      position: absolute;
      right: 55px;
      top: 0;
      padding-top: 0;
      z-index: 9;
      opacity: 1;
      transition: opacity .3s ease;

      .side-panel__menu {
        padding-top: 0;
        position: fixed;
      }

      &::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        display: none;
      }
    }

    &_side-panel {
      width: 406px;
      position: fixed;
      right: -406px;
      top: 0;
      background-color: $primary-light-color;
      height: 100%;
      padding-top: 64px;
      transition: right .3s ease;

      @media (max-width: $break-md-max) {
        padding-top: 100px;
      }

      @media (max-width: $break-xs-max) {
        width: 320px;
        right: -320px;
      }
    }

    &.opened {
      width: calc(100% - 342px);

      @media (max-width: $break-lg-max) {
        width: 100%;

        &::after {
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          content: '';
        }

        .document-detail__body_side-panel {
          box-shadow: 0 4px 8px 0 rgba($shadow-color, 0.5);
        }
      }

      .side-panel--2 {
        opacity: 0;
        z-index: -1;

        &::after {
          display: block;
        }
      }

      .document-detail__body_side-panel {
        right: 0;

        .side-panel__menu .trigger {
          transform: rotate(0deg);
        }
      }
    }
  }
}