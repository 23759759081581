@use '../abstracts/variables' as *;

.switch-el {

  label {
    cursor: pointer;

    input {
      display: none;
    }

    input:checked ~ .switch-el__btn {
      background-color: $primary-color;
      border-color: $primary-color;

      &::after {
        left: 19px;
        background-color: $primary-light-color;
      }
    }
  }

  &__btn {
    width: 40px;
    height: 23px;
    border-radius: 16px;
    background-color: $primary-light-color;
    border: 1px solid $senary-color;
    position: relative;
    transition: background-color .3s ease, border .3s ease;

    &::after {
      content: '';
      position: absolute;
      top: 2px;
      left: 2px;
      width: 17px;
      height: 17px;
      border-radius: 8px;
      background-color: $senary-color;
      transition: left .3s ease;
    }
  }
}