@use '../abstracts/variables' as *;

.custom-tooltip {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 0.875rem;
  line-height: 1.285;
  padding: 5px 10px;
  text-align: center;
  background-color: $secondary-text-color;
  color: $primary-light-color;
  // min-width: 120px;
  opacity: 0;
  transition-property: opacity;
  transform-origin: center center;
  text-align: center;
  backface-visibility: hidden;
  border-radius: 3px;
  box-shadow: 0 3px 7px 0 rgba($shadow-color-8, 0.4);
  z-index: 9999;

  &::after {
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    border-color: rgba(255,0,0,0);
    border-width: 6px;
  }

  &--top {
    transform: translate(-50%, calc(-100% - 15px)) scale(1);

    &::after {
      top: 100%;
      left: 50%;
      border-top-color: $secondary-text-color;
      margin-left: -6px;
    }
  }
  &--top-input {
    transform: translate(-50%, calc(-100% - 10px)) scale(1);
  }
  &--bottom {
    transform: translate(-50%, 15px) scale(1);

    &::after {
      bottom: 100%;
      left: 50%;
      border-bottom-color: $secondary-text-color;
      margin-left: -6px;
    }
  }
  &--left {
    transform: translate(-15px, -50%) scale(1);

    &::after {
      top: 50%;
      left: 100%;
      border-left-color: $secondary-text-color;
      margin-top: -6px;
    }
  }
  &--right {
    transform: translate(15px, -50%) scale(1);

    &::after {
      top: 50%;
      right: 100%;
      border-right-color: $secondary-text-color;
      margin-top: -6px;
    }
  }
  &--top-left {
    transform: translate(0, calc(-100% - 15px)) scale(1);
    text-align: left;

    &::after {
      top: 100%;
      left: 0%;
      border-top-color: $secondary-text-color;
      margin-left: 10px
    }
  }
  &--top-right {
    transform: translate(-100%, calc(-100% - 15px)) scale(1);
    text-align: right;

    &::after {
      top: 100%;
      right: 0%;
      border-top-color: $secondary-text-color;
      margin-right: 10px
    }
  }
  &--bottom-left {
    transform: translate(0, 15px) scale(1);

    &::after {
      bottom: 100%;
      left: 0%;
      border-bottom-color: $secondary-text-color;
      margin-left: 10px
    }
  }
  &--bottom-right {
    transform: translate(-100%, 15px) scale(1);

    &::after {
      bottom: 100%;
      right: 0%;
      border-bottom-color: $secondary-text-color;
      margin-right: 10px
    }
  }
  &--left-top {
    transform: translate(-15px, 0) scale(1);

    &::after {
      top: 10px;
      left: 100%;
      border-left-color: $secondary-text-color;
    }
  }
  &--left-bottom {
    transform: translate(-15px, -100%) scale(1);

    &::after {
      bottom: 10px;
      left: 100%;
      border-left-color: $secondary-text-color;
    }
  }
  &--right-top {
    transform: translate(15px, 0) scale(1);

    &::after {
      bottom: 10px;
      right: 100%;
      border-right-color: $secondary-text-color;
    }
  }
  &--right-bottom {
    transform: translate(15px, -100%) scale(1);

    &::after {
      bottom: 10px;
      right: 100%;
      border-right-color: $secondary-text-color;
    }
  }
}