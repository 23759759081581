.help-article {

  &__head {
    margin-bottom: 40px;
    border-bottom: 1px solid #EBECF0;
    padding-bottom: 20px;

    h2 {
      font-size: 1.875rem;
      font-weight: 500;
    }

    p {
      font-size: 0.75rem;
      color: #aaa;
      margin-top: 10px;
    }
  }

  &__body {

    h2 {
      margin-bottom: 15px;
    }

    p {
      margin-bottom: 15px;
    }

    img {
      margin-bottom: 15px;
    }
  }
}