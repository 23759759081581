@use '../abstracts/variables' as *;

.report-a-bug {
  
  &__head {
    padding: 20px;
    background-color: #EBECF0;
    border-radius: 3px 3px 0 0;

    h3 {
      display: flex;
      align-items: center;
      font-weight: 400;

      svg {
        display: block;
        margin-right: 5px;
      }
    }
  }

  &__body {
    padding: 20px;

    > p {
      font-size: 0.93875rem;
    }

    p.already-voted {
      padding: 20px;
      text-align: center;
      border: 1px solid #EBECF0;
      margin-top: 20px;
    }

    .emojis {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 30px 0;

      a {
        text-align: center;
        width: 60px;
        color: #aaa;

        svg {
          font-size: 2.5rem;
        }

        span {
          display: block;
          font-size: 0.8125rem;
        }

        &.active {
          color: #FDB400;
        }
      }
    }

    form {

      .textarea {
        margin-bottom: 30px;

        &__field {
          height: 100px;
          resize: none;
        }
      }

      .image-wrapper {
        display: flex;
        margin-bottom: 30px;
        align-items: center;

        input[type="file"] {
          display: none;
        }

        @media (max-width: $break-xs-max) {
          display: block;
        }
        
        &__left {
          margin-right: 15px;
          max-width: 150px;

          @media (max-width: $break-xs-max) {
            margin-right: 0;
            margin-bottom: 15px;
          }

          p, ul li {
            font-size: 0.875rem;
            margin-bottom: 10px;
          }

          ul {

            li {

              &:last-child {
                margin-bottom: 0;
              }

              a {
                color: #000;

                &.active {
                  color: #006EFF;
                }
              }
            }
          }
        }

        &__right {
          background-color: #EBECF0;
          flex: 1;

          .button {
            height: auto;
            padding: 10px;

            .icon {
              color: #fff;
            }
          }
          
          &_dnd,
          &_url {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 15px;
            min-height: 100px;

            p {
              font-size: 0.875rem;
              margin-top: 10px;
              text-align: center;

              a {
                color: #aaa;

                &:hover {
                  text-decoration: underline;
                }
              }
            }

            .form-input {
              margin-bottom: 0;
              width: 100%;
            }
          }
        }
      }

      .features {
        margin: 20px 0;
        overflow-y: auto;
        max-height: 214px;

        &__item {
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }

          .checkbox {
            margin-right: 5px;

            label {
              display: flex;
              align-items: center;
              padding: 10px;
              border: 1px solid #DFE1E6;
              border-radius: 4px;
              cursor: pointer;
              user-select: none;
              height: auto;

              .checkbox__label {
                font-weight: 400;
                margin-top: 1px;
              }
            }
          }
        }
      }

      .btn-wrapper {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}