@use '../abstracts/variables' as *;

.variables__text {
  white-space: pre-wrap !important;
}

.document-detail-variables {
  padding: 39px 36px;
  // padding-bottom: 800px;

  @media (max-width: $break-sm-max) {
    padding: 30px 15px;
  }

  &__head {
    margin-bottom: 32px;

    &_top {
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .form-input {
        width: 140px;
        margin-bottom: 0;
      }

      @media (max-width: $break-sm-max) {
        display: block;

        h2 {
          margin-bottom: 10px;
        }
      }
    }

    h3 {
      color: $primary-text-color;
      font-size: 1.125rem;
      font-weight: 500;
    }
  }

  &__body {

    section {
      margin-bottom: 50px;
    }

    h4 {
      color: $primary-text-color;
      font-weight: 700;
      margin-bottom: 16px;

      span {
        border-bottom: 2px solid $primary-dark-color;
      }
    }
  }
  .select_input_wrapper {
    margin-left: 10px;
    margin-right: 10px;

    &.multiselect {

      div {
        display: flex;
        align-items: center;
      }
    }
  }
  .date_picker {
    margin-left: 10px;
    margin-right: 10px;
  }

  &__foot {
    margin-top: 37px;

    h4 {
      width: calc(100% + 72px);
      margin-left: -36px;
      border-bottom: 1px solid #98BDFF;
      padding-left: 60px;
      margin-bottom: 20px;

      span {
        padding: 5px 7px 5px 11px;
        display: inline-block;
        background-color: #D9E8F5;
        border: 1px solid #98BDFF;
        border-radius: 5px 5px 0 0;
        font-size: 0.75rem;
        color: #204B91;
        line-height: 1;
        border-bottom: 0;
      }
    }
  }
}