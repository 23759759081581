@use '../abstracts/variables' as *;

.dropdown-el {
  z-index: 100;

  &.animate {
    opacity: 0;
    transform: scale(0);
    transition: opacity .2s ease, transform .2s ease;
  }

  &.animate.active {
    opacity: 1;
    transform: scale(1);
  }

  &.default-dropdown {
    background-color: $primary-light-color;
    border-radius: 3px;
    box-shadow: 0 1px 3px 0 rgba($shadow-color-5, 0.5);
    position: absolute;
    top: calc(100% + 5px);
    right: 0;
    white-space: nowrap;
    padding: 10px;
  }
}