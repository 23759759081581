@use '../abstracts/variables' as *;

.modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  &--bg-2 {

    .modal__content {
      background-color: $nonary-bg-color;
    }
  }

  &--no-padding {

    .modal__content {
      padding: 0;
    }
  }

  &--padding-sm {

    .modal__content {
      padding: 15px;
    }
  }

  &--no-bg {

    .modal__bg {
      background-color: rgba($primary-light-color, 0);
    }

    .modal__content {
      box-shadow: 0 12px 24px 0 rgba($shadow-color-7, 0.5);
    }
  }

  &__bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($primary-dark-color, 0.69);
  }

  &__inner {
    max-height: 100%;
    position: relative;
    width: 100%;
  }

  &__content-wrapper {
    padding: 50px 0;
    width: 100%;
  }

  &__content {
    max-width: 900px;
    width: 90%;
    margin: 0 auto;
    background-color: $primary-light-color;
    padding: 30px;
    position: relative;
    border-radius: 3px;
    z-index: 10;

    &_close {
      position: absolute;
      top: 5px;
      right: 5px;
      width: 34px;
      height: 34px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $nonary-text-color;
      cursor: pointer;
      transition: background-color .3s ease;
      z-index: 10;

      svg {
        font-size: 1.25rem;
        display: block;
      }

      &:hover {
        background-color: $primary-bg-color;
      }
      &:active {
        background-color: $octonary-color;
      }
    }

    .react-pdf__Page__textContent  {
      color: transparent !important;
      opacity: 0.5 !important;
    }
    .react-pdf__Page__textContent ::selection {
      background: #0000ff !important;
    }
    .react-pdf__Page {
      background-color: rgb(82, 86, 89);
      padding: 10px;
      overflow: hidden;
    }
    .react-pdf__Page__canvas {
      width: 100% !important;
      height: auto !important;
    }
  }

  &--small {

    .modal__content {
      max-width: 400px;
    }
  }

  &--medium {

    .modal__content {
      max-width: 481px;
    }
  }

  &--medium-2 {

    .modal__content {
      max-width: 593px;
    }
  }

  &--large {

    .modal__content {
      max-width: 1200px;
    }
  }

  &--xx-large {

    .modal__content {
      max-width: 1400px;
    }
  }

  iframe {

    #container-image {
      display: none;
    }
  }

  &--v2 {
    .modal__content {
      padding: 40px 0 0;
      border-radius: 8px;
      max-width: 846px;
    }

    .modal-inner {
  
      h2 {
        font-size: 1.4375rem;
        font-weight: 500;
        letter-spacing: 0.29px;
        color: $secondary-text-color;
        text-align: center;
        margin-bottom: 42px;
        padding: 0 10px;
      }
    
      &__head {
        padding: 0 40px;
        width: calc(100% + 80px);
        margin-left: -40px;
    
        @media (max-width: $break-sm-max) {
          padding: 0 20px;
          width: calc(100% + 40px);
          margin-left: -20px;
        }
    
        ul {
          display: flex;
          justify-content: center;
          align-items: center;
          border-bottom: 2px solid $nonary-border-color;
    
          li {
            color: rgba($tertiary-text-color, 0.69);
            font-size: 0.8125rem;
            font-weight: 500;
            padding-bottom: 13px;
            text-align: center;
            text-transform: uppercase;
            cursor: pointer;
            margin: 0 25px;
            position: relative;
    
            &.no-cursor {
              cursor: default;
            }
    
            &.active {
              color: $primary-color;
              
              &::after {
                content: '';
                position: absolute;
                bottom: -2px;
                left: 0;
                width: 100%;
                height: 2px;
                background-color: $primary-color;
              }
            }
          }
        }
      }

      &__body {
        padding: 30px 40px 0;

        @media (max-width: $break-sm-max) {
          padding: 30px 20px 0;
        }
      }
    }
  }
}

.signature-modal {

  .modal__content {
    padding: 40px 0 0;
    border-radius: 8px;
    max-width: 846px;
  }

  &-inner {

    h2 {
      font-size: 1.4375rem;
      font-weight: 500;
      letter-spacing: 0.29px;
      color: $secondary-text-color;
      text-align: center;
      margin-bottom: 42px;
      padding: 0 10px;
    }

    &__head {
      padding: 0 40px;
      width: calc(100% + 80px);
      margin-left: -40px;
  
      @media (max-width: $break-sm-max) {
        padding: 0 20px;
        width: calc(100% + 40px);
        margin-left: -20px;
      }
  
      ul {
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 2px solid $nonary-border-color;
  
        li {
          color: rgba($tertiary-text-color, 0.69);
          font-size: 0.8125rem;
          font-weight: 500;
          padding-bottom: 13px;
          text-align: center;
          text-transform: uppercase;
          cursor: pointer;
          margin: 0 25px;
          position: relative;
  
          &.no-cursor {
            cursor: default;
          }
  
          &.active {
            color: $primary-color;
            
            &::after {
              content: '';
              position: absolute;
              bottom: -2px;
              left: 0;
              width: 100%;
              height: 2px;
              background-color: $primary-color;
            }
          }
        }
      }
    }

    &__body {
      padding: 30px 40px 0;

      @media (max-width: $break-sm-max) {
        padding: 30px 20px 0;
      }

      .form {
        padding-top: 16px;

        &__group {
          margin-bottom: 22px;
        }
  
        .form-input {
          margin-bottom: 0;
  
          &__field {
            height: 38px;
          }
        }
  
        &__subtitle {
          font-size: 1.125rem;
          color: $secondary-bg-color;
          margin-bottom: 20px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          max-width: 711px;
          position: relative;
  
          &--2 {
            margin-bottom: 15px;
          }
  
          &--3 {
            margin-bottom: 10px;
          }
  
          .icon-button {
            width: 50px;
            height: 38px;
            align-self: flex-end;
          }

          .users-dropdown {
            min-width: 280px;
            max-height: 185px;
            overflow-y: auto;
            // padding-top: 50px !important;
      
            .form-input {
              position: sticky;
              top: -10px;
              width: 100%;
              margin-bottom: 0;
              margin-left: -10px;
              margin-top: -10px;
              width: calc(100% + 20px);
              padding: 10px;
              background-color: $primary-light-color;
              
              &__field {
                height: auto;
              }
            }
      
            p {
              font-size: 0.875rem;
              color: $secondary-text-color;
              font-weight: 500;
              padding: 5px;
              border-radius: 2px;
              cursor: pointer;
              transition: background-color .3s ease;
      
              &:hover {
                background-color: $primary-bg-color;
              }
      
              &.no-data {
                cursor: default;
      
                &:hover {
                  background-color: transparent;
                }
              }
            }
          }
        }

        &__actions {
          padding-top: 0;
          padding-bottom: 48px;
  
          .button {
            margin: 0 17px;
  
            &--primary {
              min-width: 142px;
              text-transform: uppercase;
              font-size: 0.75rem;
              letter-spacing: 0.3px;
            }
  
            &--transparent {
              font-size: 0.9375rem;
              font-weight: 400;
              color: $nonary-text-color;
            }
          }
        }

        .message {
          margin-bottom: 20px;
        }
      }
    }

    &__section {
      margin-bottom: 60px;

      &--checkbox {
        margin-bottom: 32px;
      }
    }

    .form {

      &__group {

        &--signature-modal {
          justify-content: space-between;
          max-width: 711px;
          margin-bottom: 25px !important;
          flex-wrap: wrap;

          &.form__group--dropdown {

            .custom-select {
              margin-bottom: 0;
              max-width: 340px;
              flex: 1;
              
              &__selected {
                height: 38px;
              }

              .select-dropdown{
                max-height: 167px;
                overflow-y: auto;
              }
            }
          }

          .form__error {
            width: 100%;
            margin-top: 10px;
          }

          @media (max-width: 900px) {
            display: block;

            .form-input {
              margin-bottom: 25px !important;
            }
          }
        }
        
        &_actions {
          max-width: 711px;
          
          &-bottom {
            display: flex;
            margin-top: 30px;
            justify-content: space-between;

            @media (max-width: $break-xs-max) {
              display: block;

              .delete-btn {
                margin-top: 10px;
              }
            }
          }

          .edit-btn {
            margin-right: 20px;
          }

          .delete-btn {
            width: 36px;
            height: 36px;
            color: $nonary-text-color;
            transition: color 0.3s ease, background-color 0.3s ease;

            &:hover {
              color: $secondary-color-light;
              background-color: rgba($secondary-color-light, 0.1);
            }
          }
        }

        .form-input {
          max-width: 340px;
        }
      }

      &__error {
        margin-bottom: -20px;
      }

      &__fields-group {
        margin-bottom: 30px;
      }

      .button--primary-light {
        font-weight: 400;
      }

      .field-positions {

        .delete-btn {
          width: 36px;
          height: 36px;
          color: $nonary-text-color;
          transition: color 0.3s ease, background-color 0.3s ease;
          
          &--2 {
            margin-left: auto;
          }

          &:hover {
            color: $secondary-color-light;
            background-color: rgba($secondary-color-light, 0.1);
          }
        }
      }
    }
  }

  .signatures-success {
    padding: 0 40px;
  }
}

.signature-placement-modal {
  .iframe-wrapper {
    overflow: hidden;
    height: 775px;
  }

  .iframe-container {
    width: 1200px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;

    .overlay-12 {
      position: absolute;
      top: 167px;
      left: 50%;
      width: 930px;
      height: 1315px;
      transform: translateX(-50%);
      background-color: rgba(0,0,0,0.2);
      overflow-y: scroll;
      display: none;

      .pos-1 {
        position: absolute;
        height: 136px;
        left: 0;
        top: 0;
        width: 202px;
        background-color: rgba(green, 0.2);
      }
    }
  }
}