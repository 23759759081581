@use '../abstracts/variables' as *;
@use '../abstracts/mixins' as *;

.textarea {

  &--edit-mode {

    .textarea__field {
      background-color: rgba($primary-color, 0.12);
      border-radius: 3px;
      padding: 5px 10px 4px;
      height: 94px;
      min-height: 46px;
      width: 100%;
      // resize: none;
    }
  }

  &__field {
    border: 0;
    outline: 0;
    border-radius: 3px;
    background-color: $primary-bg-color;
    font-family: $ff-primary;
    font-size: 0.875rem;
    font-weight: 700;
    color: $nonary-text-color;
    padding: 8px 14px;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    display: block;

    @include placeholder {
      color: $nonary-text-color;
    }
  }

  &--dashboard-modal {

    .textarea__field {
      background-color: transparent;
      border: 1px solid $tertiary-border-color;
      font-weight: 300;
      color: $primary-text-color;
      padding: 5px;
      min-height: 450px;
      max-height: 450px;

      @include placeholder {
        font-size: 0.75rem;
        color: $quinary-text-color;
      }
    }
  }
}