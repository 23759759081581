.sec-document-form {
  position: relative;

  .side-panel {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 406px;
    background-color: #fff;
    z-index: 4;
    box-shadow: 0 0 4px 0 hsl(0deg 0% 73% / 50%);
    padding-top: 71px;
    transition: right .3s ease;

    @media (max-width: 480px) {
      width: 320px;
    }
  }

  &.sp-closed {

    .side-panel {
      right: -351px;

      @media (max-width: 480px) {
        right: -265px;
      }

      &__menu {

        .trigger {
          transform: rotate(0deg);
        }
      }
    }
  }

  .form-content-actions {
    display: flex;
    justify-content: center;
    margin-bottom: 31px;

    &__inner {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      padding: 11px 5px 5px 5px;
      background-color: #fff;
      border: 1px solid #E2E3F9;

      button {
        min-width: 140px;
        height: 32px;
        margin: 0 6px 6px;
        padding: 0 14px;
        background-color: rgba(59,63,216,0.14);
        color: #3B3FD8;
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
        font-size: 11px;
        line-height: 13px;
        border: 0;
        text-transform: uppercase;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        transition: background-color .3s ease;
        cursor: pointer;

        svg {
          display: block;
          margin-right: 6px;
        }

        &:hover {
          background-color: rgba(59,63,216,0.34);
        }

        &.download-btn {
          background-color: #FA8072;
          color: #fff;

          &:hover {
            background-color: darken(#FA8072, 10);
          }
        }
      }
    }
  }
}

.sticky-progress-header {
  position: sticky;
  top: 0;
  z-index: 10;
}

.document-form-sticky-header {
  position: fixed;
  top: 0;
  padding: 16px 0;
  box-shadow: 0 2px 4px 0 rgba(212,212,212,0.5);
  background-color: #fff;
  z-index: 10;
  width: 100%;
  
  &__content {
    display: flex;
    align-items: center;
    position: relative;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding-right: 150px;

    @media (max-width: 767px) {
      // display: block;
    }

    .actions {
      position: absolute;
      right: 0;
      top: 2px;
      display: flex;
      align-items: center;

      ul {
        padding-left: 0;
        list-style-type: none;
        display: flex;
        align-items: center;

        li {
          padding: 0 12px 0 11px;
          position: relative;

          &::after {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            content: '';
            width: 1px;
            height: 13px;
            background-color: #2801D8;
          }

          &:first-child {
            padding-left: 0;
          }

          &:last-child {
            padding-right: 0;

            &::after {
              display: none;
            }
          }

          > div {
            cursor: pointer;

            svg {
              display: block;
              color: #2801D8;
            }
          }

          .cancel {
            display: flex;
            align-items: center;
            cursor: pointer;
            
            svg {
              color: #E74848;
              display: block;
            }
          }
        }
      }
    }

  }

  h1 {
    font-size: 19px;
    font-weight: 700;
    line-height: 26px;
    color: #2801D8 !important;
    text-align: left;
    margin: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    @media (max-width: 991px) {
      padding-left: 0;
    }
  }

  h4 {
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.12px;
    color: #2801D8;
    margin: 0 0 0 auto;
    min-width: 115px;
    text-align: right;

    @media (max-width: 767px) {
      text-align: left;
      margin-top: 10px;
      min-width: auto;
    }
  }

  .progress-bar {
    position: absolute;
    bottom: -16px;
    left: 0;
    width: calc(100% - 150px);
    height: 3px;
    background-color: rgba(40,1,216,0.2);

    &__inner {
      height: 100%;
      background-color: #2801D8;
    }
  }
}

.document-form-space {
  height: 58px;
}

.document-form-v2 {

  .q-form-wrap {

    .form-completed {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 60px;

      &__icon {

        svg {
          font-size: 90px;
          color: #2CB33C;
        }
      }

      &__text {

        p {
          color: #2CB33C;
          font-size: 20px;
          font-weight: 700;
          font-family: 'Nunito Sans', sans-serif;
        }
      }
    }
  }
}