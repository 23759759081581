.terms-page,
.privacy-page {
  padding: 0 15px;

  section {
    margin-bottom: 60px;
  }

  h1 {
    margin-bottom: 40px;
  }

  h2 {
    margin-bottom: 30px;
    font-size: 1.75rem;

    &.underline {
      text-decoration: underline;
    }

    &.highlighted {
      text-align: center;
    }
  }

  h3 {

    &.indented {
      text-indent: 30px;
    }
  }

  p, ul li, ol li {
    font-size: 1rem;
    line-height: 1.7;
    margin-bottom: 20px;

    &.subtitle {
      text-align: center;
      margin-bottom: 40px;
    }
  }

  ul {
    list-style-type: disc;
    padding-left: 20px;

    li {
      margin-bottom: 10px;

      ul {
        list-style-type: circle;
        padding-top: 10px;
      }

      ol {
        padding-top: 10px;
      }
    }
  }

  ol {
    list-style: decimal;
    padding-left: 20px;
  }

  table {
    border: 1px solid #eee;
    width: 100%;
    margin-bottom: 40px;

    th {
      border: 1px solid #eee;
      padding: 10px;
      background-color: #2800d7;
      color: #fff;
    }

    td {
      border: 1px solid #eee;
      padding: 10px;
      font-size: 0.875rem;
      line-height: 1.7;
      vertical-align: top;

      span {
        display: block;
        margin-bottom: 10px;
      }
    }
  }

  address {
    margin-bottom: 20px;

    span {
      display: block;
    }
  }
}