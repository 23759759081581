@use '../abstracts/variables' as *;
@use '../abstracts/mixins' as *;

.document-detail-sp-variables {
  padding: 50px 38px 0 24px;
  /* SCROLLING TASK */
  display: flex;
  flex-direction: column;
  height: 100%;
  /* SCROLLING TASK */

  @media (max-width: $break-xs-max) {
    padding-left: 15px;
    padding-right: 15px;
  }

  &__head-search-wrapper {
    background-color: $bg-color-11;
    padding: 10px;
    margin-bottom: 10px;
  }

  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 7px;

    @media (max-width: $break-xs-max) {
      display: block;

      h4 {
        margin-bottom: 10px;
      }
    }

    h4 {
      font-weight: 500;
      font-size: 1.0625rem;

      span {
        font-size: 0.875rem;
        margin-left: 16px;
      }
    }
  }

  &__progress {
    padding-right: 42px;
    position: relative;
    height: 18px;
    margin-bottom: 9px;
    padding-top: 6px;

    &_inner {
      width: 100%;
      height: 5px;
      border-radius: 3px;
      background-color: $senary-border-color;
      position: relative;

      div {
        height: 100%;
        background: rgb(166,204,255);
        background: linear-gradient(90deg, rgba(166,204,255,1) 0%, #00008f 100%);
        border-radius: 3px;
      }
    }

    p {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 0.9375rem;
      font-weight: 500;
      color: #00008f;
      line-height: 18px;
    }
  }

  &__search {
    
    .form-input {
      margin-bottom: 0;

      &__field {
        border: 1px solid $senary-border-color;
        color: $senary-color;
        font-size: 0.75rem;
        font-weight: 400 !important;
        padding: 8px 11px;
        
        @include placeholder {
          color: $senary-color !important;
        }
      }
    }
  }

  &__body {
    /* SCROLLING TASK */
    // flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    /* SCROLLING TASK */

    &_section {
      cursor: pointer;
      margin-bottom: 8px;

      h2 {
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.15;
        color: $primary-dark-color;
        padding: 7px 46px 6px 11px;
        width: 100%;
        border-radius: 3px;
        position: relative;
        // white-space: nowrap;
        // text-overflow: ellipsis;
        // overflow: hidden;
        // transition: background-color .3s ease;

        &:hover,
        &.active {
          background-color: $bg-color-19;
        }

        .progress {
          position: absolute;
          top: 50%;
          right: 10px;
          font-size: 0.9375rem;
          color: $bg-color-16;
          font-weight: 500;
          transform: translateY(-50%);
        }

        .icon {
          position: absolute;
          top: 50%;
          right: 6px;
          color: $primary-color;
          transform: translateY(-50%);
          display: none;
          transition: transform .3s ease;

          svg {
            display: block;
          }

          &:hover {
            border-radius: 50%;
            background-color: $primary-light-color;
          }
        }

        &:hover {
          .progress {
            display: none;
          }
          .icon {
            display: block;
          }
        }
      }

      &--active {
        border-radius: 3px 3px 8px 8px;
        background-color: $form-el-bg-color-1;

        h2 {
          background-color: $bg-color-19;

          .progress {
            display: none;
          }
          .icon {
            display: block;
            transform: translateY(-50%) rotate(90deg);
          }
        }
      }
    }

    ul {
      padding: 16px 0;

      li {
        font-size: 0.875rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 4px;
        cursor: pointer;
        padding: 7px 12px 7px 10px;
        position: relative;
        border-left: 2px solid transparent;
        // transition: background-color .3s ease;

        &:hover,
        &.active {
          background-color: $primary-light-color;
          border-color: $octonary-color;

          p {
            color: $primary-color;
          }
        }

        p {
          width: 125px;
          // word-break: break-all;
          // white-space: nowrap;
          // text-overflow: ellipsis;
          // overflow: hidden;
        }

        // .tooltip-wrapper {

        //   .tooltip {
        //     max-width: 200px;
        //     width: 100%;
        //     white-space: normal;
        //   }

        //   &--2 {

        //     .tooltip {
        //       max-width: none;
        //       white-space: nowrap;
        //       width: auto;
        //     }
        //   }
        // }

        p.input-value {
          text-align: right;
          width: 125px;
          color: $text-color-13;
          word-break: break-all;

          span {
            // display: block;
            // white-space: nowrap;
            // text-overflow: ellipsis;
            // overflow: hidden;
            // max-width: 100%;
          }
        }
      }
    }
  }

  &__foot {

    &_title {
      border-bottom: 1px solid #98BDFF;
      display: flex;
      justify-content: center;
      margin-bottom: 20px;

      span {
        padding: 5px 7px 5px 11px;
        display: inline-block;
        background-color: #D9E8F5;
        border: 1px solid #98BDFF;
        border-radius: 5px 5px 0 0;
        font-size: 0.75rem;
        color: #204B91;
        line-height: 1;
        border-bottom: 0;
      }
    }

    .document-detail-sp-variables__body_section {
      background-color: transparent;

      ul {
        
        li {
          font-size: 0.875rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 8px;
          cursor: pointer;
          padding: 0 14px;
          position: relative;

          p {
            width: 125px;

            &.input-value {
              text-align: right;
              color: $text-color-13;
            }
          }
        }
      }
    }
    
  }
}