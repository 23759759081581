.sec-wrap--beci {

  h1,
  h2,
  .hero__h,
  .sec__h2 {
    color: #2800d7;
    font-family: 'Nunito', sans-serif;
  }
  
  .hero__h span {
    color: #2800d7 !important;
  }

  .hero__h--beci {
    font-weight: 900;
    font-size: 36px;
    line-height: 49px;
    margin-bottom: 36px;
    max-width: 986px;
    margin-left: auto;
    margin-right: auto;
  }

  .subtitle-2--beci {
    font-weight: 700;
    font-size: 22px;
    line-height: 30px;
    text-align: center;
    max-width: 921px;
    margin: 0 auto 100px;
  }

  .beci-home-steps {

    .col {

      .faciles-cont {

        div {
          width: 63px;
          height: 66px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 24px;
        }

        img {
          width: auto;
          height: auto;
          max-width: 63px;
          max-height: 66px;
          display: block;
          margin-bottom: 0;
        }
      }
    }
  }

  .c-btn.btn--red {
    color: #2800d7;
    border-color: #2800d7;

    &:hover {
      background-color: #2800d7;
      color: #fff;
      
      h4.nav__h {
        color: #fff !important;
      }
    }
  }

  .w-nav-brand {

    @media (max-width: 480px) {
      padding-left: 0;
    }
  }

  .nav-link-wrap--lang {

    .nav-btn {
      border-width: 1px;

      &:hover {

        .login-profile-icon {
          color: #212529;
        }
      }
    }

    .login-profile-icon {
      display: none;
    }

    @media (max-width: 991px) {
      margin-right: 0;
      margin-left: 15px;

      .nav-btn {
        display: flex;
        margin-top: 0;
      }
    }

    @media (max-width: 480px) {
      .nav-btn {
        width: 56px;
        margin-left: 0px;

        h4:not(.support__h) {
          display: none;
        }

        .login-profile-icon {
          display: flex;
          font-size: 24px;
        }
      }
    }
  }

  .nav-link-login-menu-btn-wrap {
    display: flex;
    align-items: center;
  }

  .auth-dialog--beci {

    .btn {

      .nav__h {
        line-height: 1.3;
        margin: 0;
        padding: 3px 0;
      }
    }
  }

  .tabs__cont-wrap {

    .link {

      svg {
        color: #2800d7;
      }
    }
  }

  .tabs-menu {

    .tab-link__line.highlighted {
      background-color: #2800d7;
    }
  }

  .tabs {

    &__link {

      &:hover {
        background-color: #2800d7;
        color: #fff;
      }

      &:hover,
      &.highlighted {
        background-color: #fff;

        &::after {
          content: '';
          height: 3px;
          width: 100%;
          position: absolute;
          bottom: -1px;
          left: 0;
          background-color: #2800d7;
        }

        // &::before {
        //   content: '';
        //   height: 30px;
        //   width: 1px;
        //   position: absolute;
        //   left: -1px;
        //   top: auto;
        //   right: auto;
        //   bottom: auto;
        //   background-color: #fff;
        // }

        // .tabs__g-line {
        //   display: none;
        // }
      }
    }
  }

  .sticky-collapse {
    z-index: 1001;
  }

  .header-bottom {

    .nav__g-line {
      display: none;
    }
  }

  .button {

    &--primary {
      background-color: #2800d7;

      &:hover {
        background-color: darken(#2800d7, 10);
      }
    }
  }

  .d-title-link-2 {

    .title-wrap-2 {
      opacity: 1;
    }
    
    .title-wrap-2 .cont__h4-2 {
      color: #2800d7;
    }
    
    &:visited {

      .title-wrap-2 .cont__h4-2 { 
        color: #A18EF5;
      }
    }
    
    &:hover {

      .title-wrap-2 .cont__h4-2 {
        color: #1A008C;
      }
    }

  }

  .f-form__label {
    margin-bottom: 2px;
  }

  .doc-time-wrapper {
    display: inline-flex;
    align-items: center;
    border: 1px solid rgba(40,1,216,0.13);

    @media (max-width: 600px) {
      display: inline-block;
    }

    .d-hero__title {
      display: flex;
      align-items: center;
      padding: 10px 24px 10px 10px;
      font-size: 15px;
      font-weight: 400;
      letter-spacing: 0px;
      color: #1B2421;

      @media (max-width: 600px) {
        width: 100%;
      }

      &:first-child {
        border-right: 1px solid rgba(40,1,216,0.13);

        @media (max-width: 600px) {
          border-bottom: 1px solid rgba(40,1,216,0.13);
          border-right: 0;
        }
      }

      &:last-child {
        padding-left: 19px;

        @media (max-width: 600px) {
          padding-left: 10px;
        }
      }

      svg {
        display: block;
        margin-right: 9px;
        color: #2800d7;
      }

      .d-hero__span {
        margin-left: 4px;
      }
    }
  }

  .menu-btn {

    img {
      max-width: 32px;
    }
  }

  .document-form-v2 {
    padding-top: 0;
  }

  .MuiLinearProgress-root {
    background-color: rgba(40, 1, 216, 0.2);
  }

  .MuiLinearProgress-barColorPrimary {
    background-color: #2800d7;
  }

  .profile-select-field {
    width: 430px;
    height: 40px;
    padding: 8px 12px;

    @media (max-width: 991px) {
      width: 85%;
    }

    @media (max-width: 767px) {
      width: 100%;
    }
    
    &::before {
      border-color: rgba(51, 51, 51, 0.1);
    }

    .MuiSelect-select:focus {
      background-color: transparent;
    }
  }

  .download-title-wrap {

    .download-title {
      color: #2800d7;
    }

    .download-icon {
      fill: #2800d7;
    }

    h4 {
      margin-top: 0;
    }

    .w-inline-block {
      display: inline-flex;
      align-items: center;
    }

    .close-icn {

      .svg-icon {
        font-size: 32px;
      }
    }

    .svg-icon {
      color: #2800d7;
      font-size: 24px;
      margin-right: 7px;
      display: block;
    }
  }

  .checkbox-3.w--redirected-checked {
    background-color: #2800d7;
  }

  .auth-doc-link {
    color: #2800d7;
  }

  .c-btn-line-wrap {

    @media (max-width: 991px) {
      .c-btn.remove {
        display: flex;
      }
    }
  }

  .loading-popup {
    width: 90%;
    max-width: 400px;
    background-color: #fff;
    border-radius: 4px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      color: #333;
      text-align: center;
      margin-bottom: 10px;
    }
  }

  .w-tab-content--profile {

    .w-tab-pane {
      padding-bottom: 0;
    }

    .change-password-link {
      color: var(--color-beci-blue-light);
      text-decoration: none;
      margin-left: 5px;

      &:hover {
        text-decoration: underline;
      }
    }

    .delete-profile-btn {
      color: var(--color-simplyMove-orange);
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    .profile-save-btn {
      margin-top: 20px;
    }
  }
}

.cookies-modal--beci {
  
  .switch-el {
  
    label {
  
      input:checked ~ .switch-el__btn {
        background-color: #2800d7;
        border-color: #2800d7;
      }
    }
  }

  .button {

    &--primary {
      background-color: #2800d7;

      &:hover {
        background-color: darken(#2800d7, 10);
      }
    }
  }
}

.account-menu--beci {

  .MuiPaper-root {
    border-radius: 0;
    box-shadow: 0 10px 15px 0 rgba(226,226,226,0.5);
    border: 1px solid #F1F1F2;

    ul.MuiList-root {
      padding: 20px;

      li {
        border: 1px solid #2802D6;
        padding: 8px 8px 7px;
        display: flex;
        align-items: center;
        color: #2801D8;
        font-size: 13px;
        font-family: 'Rubik', sans-serif;
        font-weight: 500;
        text-transform: uppercase;
        margin-bottom: 15px;
        letter-spacing: 0px;
        min-width: 170px;

        &:last-child {
          margin-bottom: 0;
        }

        svg {
          display: block;
          font-size: 24px;
          margin-right: 9px;
        }
      }
    }
  }
}

.form-actions {
  display: flex;
  align-items: center;
  justify-content: center;

  .prev {
    margin-right: 10px;
  }
}

.sec-wrap--affinicia {

  @media (max-width: 767px) {
    .document-form-footer {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
  }

  @media (max-width: 991px) {
    overflow: visible;

    .under-footer,
    .under-footer-content {
      padding-left: 0;
      flex-wrap: wrap;
      text-align: center;
    }

    .under-footer > .footer-link__wrap {
      width: 100%;
    }
  }

  > .sec-wrap {

    @media (max-width: 991px) {
      overflow: visible;
    }
  }

  .navbar-logo--affinicia {
    max-height: 50px;
    max-width: 108px;
  }

  .footer--affinicia {
    z-index: 5;
  }

  .makeStyles-backdrop-1 {
    max-width: 100% !important;
  }
}

.sec-wrap--simplymove {

  .tabs {

    &__link {

      h4.d-cont__h4.highlighted {
        color: salmon;
      }

      &.highlighted {

        h4.d-cont__h4  {
          color: #fff;
        }
      }
    }
  }

  .tabs-menu {

    a {
      width: 33.33%;
    }
  }

  .simplymove-backdrop {
    background-color: #fff;

    .loading-logo {
      max-width: 150px;
      display: block;
    }
  }

  .date-picker-container {

    input {
      min-width: 200px;
    }
  }
}

.doc-preview-dialog--beci,
.doc-preview-dialog--affinicia {

  .MuiPaper-root {
    background-color: transparent;
    box-shadow: none;
  }

  .pdf-container {

    .preview-container {
      margin-left: 0;
    }

    .pdf-page {

      &--skeleton {
        background-color: #fff;
      }
    }
  }
}

.folder-wrapper {

  &.droppable {
    background-color: #eee;
  }
}

.free-documents-wrapper {

  &.droppable {
    background-color: #eee;
  }
}

.add-new-folder-trigger {
  cursor: default;

  span {
    cursor: pointer;
  }
}

.sec-wrap--axa_fr {

  .tabs {

    &__link {

      &.highlighted {

        h4 {
          color: #fff !important;
        }
      }

      h4.highlighted {
        color: salmon;
      }
    }
  }
}